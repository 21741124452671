import axios, { AxiosInstance } from 'axios'
import { environment } from '../environment'

export class Server {
    translationsUrl: string = 'https://eterfy.s3.amazonaws.com/staging/localization/LANGUAGE_PLACEHOLDER.json'
    axiosInstance: AxiosInstance

    constructor () {
        this.axiosInstance = axios.create({
            timeout: 50000
        })
        console.log(environment.REACT_APP_LUXOR_LOCALIZATION_FILES_URL)
        console.log(process.env.REACT_APP_LUXOR_LOCALIZATION_FILES)
    }

    fetchTranslations = (language: string) => {
        const data = this.get(this.translationsUrl.replace('LANGUAGE_PLACEHOLDER', language))
        console.log('data = ' + data)
        return data
    }

    private get = (url: string) =>
        this.axiosInstance.get(url)
            .then(response =>
                Promise.resolve(response.data)
            )
            .catch(error => {
                console.log(error)
                return Promise.reject(error)
            })
}
