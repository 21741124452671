import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/index.css'
import './assets/css/fonts.css'
import { App } from './App'

ReactDOM.render(
    <App/>,
    document.getElementById('root')
)
