export class LandingConfiguration {
    title: string
    descriptionStart: string
    brand: string
    descriptionEnd: string
    moreInfo: string
    contactInfo: string
    displayLink: string
    link: string

    constructor (
        title: string,
        descriptionStart: string,
        brand: string,
        descriptionEnd: string,
        moreInfo: string,
        contactInfo: string,
        displayLink: string,
        link: string
    ) {
        this.title = title
        this.descriptionStart = descriptionStart
        this.brand = brand
        this.descriptionEnd = descriptionEnd
        this.moreInfo = moreInfo
        this.contactInfo = contactInfo
        this.displayLink = displayLink
        this.link = link
    }
}
