import { TranslationKeys } from '../TranslationKeys'
import { ITranslationService } from '../../interfaces/ITranslationService'
import { FallbackTranslationService } from './FallbackTranslationService'
import { Languages } from '../Languages'
import { LandingLocalization } from '../LandingLocalization'
import { TranslationService } from './TranslationService'

export class LocalizationService {
    private translation: ITranslationService
    private fetchTranslations: (language: string) => Promise<any>

    constructor (callback: (language: string) => (Promise<any>)) {
        this.fetchTranslations = callback
        this.translation = new FallbackTranslationService(Languages.defaultLanguage)
    }

    setLanguage = async (language: string) =>
        this.fetchTranslations(language)
            .then(translations => this.translation = new TranslationService(translations))
            .catch(_ => this.translation = new FallbackTranslationService(language))

    landing = () =>
        new LandingLocalization(
            this.translation.translate(TranslationKeys.eterfy_landing_title),
            this.translation.translate(TranslationKeys.eterfy_landing_description_start),
            this.translation.translate(TranslationKeys.eterfy_landing_brand),
            this.translation.translate(TranslationKeys.eterfy_landing_description_end),
            this.translation.translate(TranslationKeys.eterfy_landing_more_info),
            this.translation.translate(TranslationKeys.eterfy_landing_contact_info),
            this.translation.translate(TranslationKeys.eterfy_landing_contact_display_link)
        )
}
