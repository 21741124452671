import { TranslationKeys } from '../TranslationKeys'
import { Languages } from '../Languages'
import { ITranslationService } from '../../interfaces/ITranslationService'

export class FallbackTranslationService implements ITranslationService {
    private readonly language: string
    languages: Map<string, Map<string, string>>

    constructor (language: string) {
        this.language = language

        this.languages = new Map([
            [Languages.spanish, this.spanishDictionary()],
            [Languages.portuguese, this.portugueseDictionary()],
            [Languages.english, this.englishDictionary()]
        ])
    }

    translate = (key: string) => {
        const current = this.languages.has(this.language)
            ? this.languages.get(this.language) !
            : this.languages.get(Languages.defaultLanguage) !

        return current.has(key) ? current.get(key) ! : key.toUpperCase()
    }

    private spanishDictionary = () =>
        new Map([
            [TranslationKeys.eterfy_landing_title, '¡Conócenos!'],
            [TranslationKeys.eterfy_landing_description_start, ' es una suite de gamificación de '],
            [TranslationKeys.eterfy_landing_description_end, ' que conecta marcas y audiencias por medio de experiencias lúdicas'],
            [TranslationKeys.eterfy_landing_brand, 'etermax Brand Gamification'],
            [TranslationKeys.eterfy_landing_more_info, '¿Quieres saber más??'],
            [TranslationKeys.eterfy_landing_contact_info, 'Escríbenos a brandgamification@etermax.com o visita nuestra '],
            [TranslationKeys.eterfy_landing_contact_display_link, 'web']
        ])

    private portugueseDictionary = () => new Map<string, string>([])

    private englishDictionary = () => new Map<string, string>([])
}
