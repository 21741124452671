import React, { Component } from 'react'
import { ConfigurationProvider } from './infrastructure/ConfigurationProvider'
import { Server } from './infrastructure/Server'
import { LandingConfiguration } from './delivery/LandingConfiguration'
import { Languages } from './infrastructure/localization/Languages'
import { AppState } from './AppState'

export class App extends Component<any, AppState> {
    private configurationProvider: ConfigurationProvider
    private configuration: LandingConfiguration
    private vwReference = 122
    private widthReference = 1920

    constructor (props: any) {
        super(props)
        this.state = {
            ready: false
        }
        const server = new Server()
        this.configurationProvider = new ConfigurationProvider(server)
    }

    componentDidMount = async () => {
        const language = this.fetchLanguage()
        await this.configurationProvider.setLanguage(language)
        this.configuration = this.configurationProvider.landing()
        this.setState({ ready: true })
    }

    fetchLanguage = () => {
        const navigatorLanguage = navigator.language
        let language = Languages.defaultLanguage

        for (const supportedLanguage of Languages.supportedLanguages) {
            if (navigatorLanguage.includes(supportedLanguage)) {
                language = supportedLanguage
            }
        }

        return language
    }

    render = () => this.state.ready ? this.renderContent() : <></>

    private renderContent () {
        return <div className="screen">
            <div className="degrade-circle" style={this.setWidthCondition() ? { width: this.widthCalc() + 'vw' } : { width: '' }}/>
            <div className="screen-content">
                <div className="screen-sub-content">
                    <div className="eterfy-logo"/>
                    <div className="panel">
                        <div className="title">{this.configuration.title}</div>
                        <div className="main-content">
                            <div className="main-sub-content">
                                <div className="text normal inline">
                                    {this.configuration.descriptionStart}
                                    <div className="text bold inline">
                                        etermax
                                    </div>
                                </div>
                                <div className="text bold">
                                    {this.configuration.brand}
                                </div>
                            </div>
                            <div className="main-sub-content text normal">{this.configuration.descriptionEnd}</div>
                        </div>
                        <div className="text bold question">{this.configuration.moreInfo}</div>
                        <div className="links-container">
                            <div className="link-sub-container">
                                <div className="text bold">{this.configuration.contactInfo}</div>
                                <div className="text link">
                                    <a href="mailto:brandgamification@etermax.com">brandgamification@etermax.com</a>
                                </div>
                            </div>
                            <div className="link-sub-container">
                                <div className="text bold">{this.configuration.displayLink}</div>
                                <div className="text link">
                                    <a href="https://www.etermax.com/brandgamification">etermax.com/brandgamification</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer"/>
            </div>
        </div>
    }

    private widthCalc = () => (window.innerWidth * this.vwReference) / this.widthReference

    private setWidthCondition = () => window.innerWidth > window.innerHeight && window.innerWidth > this.widthReference
}
