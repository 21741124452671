import { ITranslationService } from '../../interfaces/ITranslationService'

export class TranslationService implements ITranslationService {
    private readonly map: any

    constructor (translations: any) {
        console.log(translations)
        this.map = translations
    }

    translate = (key: string) => this.map && this.map[key]
        ? this.map[key]
        : key.toUpperCase()
}
