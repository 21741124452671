import { LandingLocalization } from './localization/LandingLocalization'
import { LandingConfiguration } from '../delivery/LandingConfiguration'

export class ConfigurationFactory {
    landing = (localization: LandingLocalization) =>
        new LandingConfiguration(
            localization.title,
            localization.descriptionStart,
            localization.brand,
            localization.descriptionEnd,
            localization.moreInfo,
            localization.contactInfo,
            localization.displayLink,
            'https://etermax.com/brandgamification/'
        )
}
