import { ConfigurationFactory } from './ConfigurationFactory'
import { LocalizationService } from './localization/services/LocalizationService'
import { Server } from './Server'

export class ConfigurationProvider {
    private server: Server
    private localization: LocalizationService
    private factory: ConfigurationFactory

    constructor (server: Server) {
        this.server = server
        this.localization = new LocalizationService(server.fetchTranslations)
        this.factory = new ConfigurationFactory()
    }

    landing = () => this.factory.landing(this.localization.landing())

    setLanguage = async (language: string) => await this.localization.setLanguage(language)
}
